import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import { AppState } from '../createStore';
import {
  countItemsInCart,
  CouponSuccess,
  Dispatch,
  getDisplayableDiscounts,
  getDisplayableOrderItem,
  getMinOrderPriceDetails,
  getPriceComponentsAndOrderCharges,
  ItemsHash,
  Menu,
  OrderItem,
  Restaurant,
  Tip,
  getMaxRedeemablePoints,
  convertCentsToLoyaltyPoints,
  convertLoyaltyPointsToCents,
} from '@wix/restaurants-client-logic';
import { loyaltyProgramSelector } from './loyaltyProgramSelector';
import { deliveryPartnerFeeSelector } from './deliveryProviderFeeSelector';

function cartSummarySelectorInner(
  dispatch: Dispatch,
  menu: Menu,
  orderItems: OrderItem[],
  isMobile: boolean,
  restaurant: Restaurant,
  itemsHash: ItemsHash,
  loyaltyProgram: ReturnType<typeof loyaltyProgramSelector>,
  coupon?: CouponSuccess,
  tip?: Tip,
  loyaltyPointsToRedeem?: number,
  deliveryPartnerFee?: number,
) {
  const dispatchType = dispatch.type;
  const dispatchTime = moment(dispatch.time).tz(restaurant.timezone);
  const platform = isMobile ? 'mobileweb' : 'web';
  const chargesV2 = menu.chargesV2 || [];
  const address = dispatch.type === 'delivery' ? dispatch.address : undefined;
  const couponHashCode = coupon?.couponHashCode;

  const getPriceComponentsAndOrderChargesArgs = {
    dispatchType,
    dispatchTime,
    orderItems,
    platform,
    chargesV2,
    restaurant,
    address,
    couponHashCode,
    tip,
    redeemAmount: 0,
    deliveryPartnerProps: {
      deliveryPartnerFee,
      shouldConsiderDeliveryPartner: true,
    },
  };

  const {
    priceComponents: { subtotal: rawSubtotal },
  } = getPriceComponentsAndOrderCharges(getPriceComponentsAndOrderChargesArgs);

  const { isLoyaltyRedeemActive, pointsRequired, pointsBalance, currencyValue } = loyaltyProgram;

  const { maxRedeemableCents, maxRedeemablePoints } = getMaxRedeemablePoints({
    subtotal: rawSubtotal,
    pointsRequired,
    pointsBalance,
    currencyValue,
  });

  const points =
    typeof loyaltyPointsToRedeem === 'number' && loyaltyPointsToRedeem > 0
      ? convertLoyaltyPointsToCents({ pointsAmount: loyaltyPointsToRedeem, pointsRequired, currencyValue })
      : 0;

  const redeemAmount =
    isLoyaltyRedeemActive && loyaltyPointsToRedeem !== 0 ? Math.min(maxRedeemableCents, points || Number.MAX_VALUE) : 0;

  const redeemPoints = convertCentsToLoyaltyPoints({ centsAmount: redeemAmount, pointsRequired, currencyValue });

  const { orderCharges, priceComponents, displayablePriceComponents } = getPriceComponentsAndOrderCharges({
    ...getPriceComponentsAndOrderChargesArgs,
    redeemAmount,
  });

  const displayableDiscounts = getDisplayableDiscounts({
    menu,
    dispatchTime,
    dispatchType,
    totalOrderPrice: priceComponents.total,
    platform,
    couponHashCode,
    orderCharges,
    restaurant,
  });

  const displayableOrderItems = orderItems.map((orderItem) =>
    getDisplayableOrderItem({
      menu,
      dispatchTime: moment(dispatchTime).tz(restaurant.timezone),
      platform: isMobile ? 'mobileweb' : 'web',
      orderItem,
      itemsHash,
      currency: restaurant.currency,
      locale: restaurant.locale,
      dispatchType,
    }),
  );
  const itemCount = countItemsInCart(displayableOrderItems);

  const { displayableMinOrderPrice } = getMinOrderPriceDetails({
    totalOrderPrice: priceComponents.subtotal,
    restaurant,
    dispatchType,
  });

  return {
    dispatchTime,
    totalOrderPrice: priceComponents.total,
    orderCharges,
    priceComponents,
    displayableDiscounts,
    displayablePriceComponents,
    displayableOrderItems,
    itemCount,
    minOrderPrice: displayableMinOrderPrice,
    redeemCents: redeemAmount,
    redeemPoints,
    maxRedeemableCents,
    maxRedeemablePoints,
  };
}

export const cartSummarySelector = createSelector(
  [
    (state: AppState) => state.checkout.dispatch,
    (state: AppState) => state.session.menu,
    (state: AppState) => state.cart.orderItems,
    (state: AppState) => state.platformParams.isMobile,
    (state: AppState) => state.session.restaurant,
    (state: AppState) => state.session.itemsHash,
    (state: AppState) => loyaltyProgramSelector(state),
    (state: AppState) => (state.cart.coupon?.type === 'success' && state.cart.coupon) || undefined,
    (state: AppState) => state.checkout.tip,
    (state: AppState) => state.checkout.loyaltyPointsToRedeem,
    (state: AppState) => deliveryPartnerFeeSelector(state),
  ],
  cartSummarySelectorInner,
);
